import { render, staticRenderFns } from "./PdfJohariZoneRenderer.vue?vue&type=template&id=232a1750&scoped=true&"
import script from "./PdfJohariZoneRenderer.vue?vue&type=script&lang=ts&"
export * from "./PdfJohariZoneRenderer.vue?vue&type=script&lang=ts&"
import style0 from "./PdfJohariZoneRenderer.vue?vue&type=style&index=0&id=232a1750&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232a1750",
  null
  
)

export default component.exports