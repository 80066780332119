
import { defineComponent, PropType } from 'vue';
import type { TranslateResult } from 'vue-i18n';

export default defineComponent({
  name: 'PdfPage',

  props: {
    title: {
      type: String as PropType<string | TranslateResult>,
      default: '',
    },
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    hasTitle(): boolean {
      return !!this.title || !!this.$slots.title;
    },
  },
});
