
import { defineComponent } from 'vue';
import { tsxassApi } from '@/services/api';
import PdfHeatMapRenderer from './PdfHeatMapRenderer.vue';

export default defineComponent({
  name: 'PdfGeneralQuestionsHeatMapRenderer',

  extends: PdfHeatMapRenderer,
  computed: {
    fixedColumns() {
      return [
        {
          key: 'name',
          title: this.$t('analytics.additionalQuestions.competency') as string,
          isFixed: true,
          width: 130,
          cellClass: 'tt-light-mono-100--text text-cell',
        },
        {
          key: 'experts',
          title: this.$t('analytics.additionalQuestions.expertsCount') as string,
          hint: this.$t('analytics.additionalQuestions.expertsCountHint') as string,
          isFixed: true,
          width: 24,
          align: 'center',
          cellClass: 'text-cell',
        },
        {
          key: 'declined',
          title: this.$t('analytics.additionalQuestions.rejectionPercent') as string,
          hint: this.$t('analytics.additionalQuestions.rejectionPercentHint') as string,
          isFixed: true,
          width: 24,
          align: 'center',
          cellClass: 'text-cell',
        },
        {
          key: 'average',
          title: this.$t('analytics.pdf.heatMap.average') as string,
          isFixed: true,
          width: 40,
          align: 'center',
          cellClass: 'text-cell',
        },
        {
          disabled: !this.chartData.settings?.displayBenches,
          key: 'bench',
          title: this.$t('analytics.pdf.heatMap.bench') as string,
          isFixed: true,
          width: 24,
          align: 'center',
          cellClass: 'text-cell',
          format: (val: number) => val.toFixed(1),
        },
      ].filter((c) => !c.disabled);
    },
    pageTitle() {
      return this.$t('analytics.heatMap');
    },
  },

  methods: {
    async loadChartData() {
      try {
        const { data } = await tsxassApi.getV1AnalyticsFeedbackGeneralQuestionsTemp(
          this.surveyId,
          this.userId || undefined,
        );
        this.chartData = {
          ...this.chartData,
          groups: data.groups || [],
          items: data.items || [],
          settings: data.settings || {
            minValue: 1,
            maxValue: 5,
            displayBenches: false,
          },
        };
      } catch (err) {
        this.$emit('error');
      } finally {
        this.dataIsLoaded = true;
      }
    },
  },
});
