
import { defineComponent, PropType } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import { V1EntitiesAnalyticsSurveysPagesPageAnswer } from '@/services/api/tsxass';
import { getElementsOuterHeights } from '@/helpers/getElementOuterHeight';

interface DestructorItem {
  id: number,
  title: string,
  description: string,
}

export default defineComponent({
  name: 'PdfDestructorsRenderer',

  components: {
    PdfPage,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    pageTitle: {
      type: String,
      default: '',
    },
    answers: {
      type: Array as PropType<V1EntitiesAnalyticsSurveysPagesPageAnswer[]>,
      default: () => ([]),
    },
  },

  data() {
    return {
      // Высота зоны контента страницы
      pageContentHeight: 0,
      // Значения высот элементов на странице
      elementsHeight: {} as Record<string, number>,
    };
  },

  computed: {
    // Список деструкторов
    destructors(): DestructorItem[] {
      const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

      return this.answers.map((answer, index) => ({
        id: index,
        title: answer.summary,
        description: capitalizeFirstLetter(answer.description),
      }));
    },
    // Деструкторы, разделенные на страницы
    pages(): DestructorItem[][] {
      // Итоговый массив
      const pages = [[]] as DestructorItem[][];
      // Обрабатываемый список
      const destructors = [...this.destructors];
      // Индекс заполняемой в данный момент страницы
      let currentPageIndex = 0;
      // Оставшаяся незаполненная высота на текущей странице
      let freeHeight = this.pageContentHeight;

      while (destructors.length) {
        const currentDestructor = destructors[0];
        const destructorHeight = this.elementsHeight?.[this.getDestructorElId(currentDestructor.id)] || 0;

        // Если не осталось места для деструкторов, создаем новую страницу
        if (destructorHeight > freeHeight) {
          currentPageIndex += 1;
          pages.push([]);
          freeHeight = this.pageContentHeight;
          // eslint-disable-next-line no-continue
          continue;
        }

        pages[currentPageIndex].push(destructors.shift()!);
        freeHeight -= destructorHeight;
      }

      return pages;
    },
  },

  watch: {
    pages: {
      immediate: true,
      async handler() {
        // Рендерер готов тогда, когда список страниц рассчитался с учетом высоты элементов
        if ((this.pageContentHeight && Object.values(this.elementsHeight).length) || !this.answers.length) {
          await this.$nextTick();
          this.collectAndEmitImages();
        }
      },
    },
  },

  async mounted() {
    this.pageContentHeight = this.$el.querySelector('.page-content')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }

    this.calculateElementsHeight();
  },

  methods: {
    getDestructorElId(id: number) {
      return `destructor-${id}`;
    },
    calculateElementsHeight() {
      this.elementsHeight = getElementsOuterHeights(this.$el as HTMLElement);
    },
  },
});
