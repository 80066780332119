
import { defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import { getElementsOuterHeights } from '@/helpers/getElementOuterHeight';
import {
  V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion,
  V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';

interface AnswerWithId extends V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer {
  id: string,
}

interface GroupsListItem {
  question?: V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion,
  questionNumber?: number,
  answers: AnswerWithId[],
}

// Отступ снизу списка ответов (px)
const ANSWERS_LIST_MB = 16;

export default defineComponent({
  name: 'PdfAnswersToOpenQuestionsRenderer',

  components: {
    PdfPage,
    NoFilteredDataStub,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      chartData: {
        questions: [] as V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion[],
        answers: [] as V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer[],
      },
      // Высота зоны контента страницы
      pageContentHeight: 0,
      // Значения высот элементов на странице
      elementsHeight: {} as Record<string, number>,
    };
  },

  computed: {
    answerGroups() {
      const answersMap = this.chartData.answers.reduce((
        acc,
        answer: V1EntitiesAnalyticsFeedbackOpenQuestionAnswersAnswer,
      ) => {
        if (!acc[answer.questionId]) {
          acc[answer.questionId] = [];
        }
        acc[answer.questionId].push({
          ...answer,
          id: `q${answer.questionId}a${acc[answer.questionId].length}`,
        });
        return acc;
      }, {} as Record<string, AnswerWithId[]>);

      return this.chartData.questions.map((question, index) => ({
        question,
        questionNumber: index + 1,
        answers: answersMap[question.id],
      }));
    },
    // Группы ответов, разделенные на страницы
    pages(): GroupsListItem[][] {
      // eslint-disable-next-line arrow-body-style
      const getQuestionHeight = (question: V1EntitiesAnalyticsFeedbackOpenQuestionAnswersQuestion) => {
        return this.elementsHeight?.[this.getQuestionTitleElId(question.id)] || 0;
      };
      // eslint-disable-next-line arrow-body-style
      const getAnswerHeight = (answer: AnswerWithId) => {
        return this.elementsHeight?.[this.getAnswerCardElId(answer?.id)] || 0;
      };

      // Итоговый массив
      const pages = [[]] as GroupsListItem[][];
      // Обрабатываемый список групп вопросов
      let groups = JSON.parse(JSON.stringify([...this.answerGroups])) as GroupsListItem[];

      // Индекс заполняемой в данный момент страницы
      let currentPageIndex = 0;
      // Оставшаяся незаполненная высота на текущей странице
      let freeHeight = this.pageContentHeight;

      while (groups.length) {
        const currentGroup = groups[0];
        // Если нет ответов, то все равно выводим вопрос и заглушку "Нет ответов"
        if (!(currentGroup?.answers)) {
          currentGroup.answers = [
            {
              id: `q${currentGroup?.question?.id}a0`,
              questionId: currentGroup?.question?.id,
              comment: this.$t('analytics.pdf.openQuestions.noAnswers'),
              noAnswers: true,
            },
          ];
        }
        // Высота заголовка у списка (при переносе ответов на след. страницу - заголовок повторно не выводится)
        const curGroupTitleHeight = currentGroup.question?.name
          ? getQuestionHeight(currentGroup.question)
          : 0;

        // Если не влезает хотя бы одна строка из списка, создаем новую страницу
        if (curGroupTitleHeight + getAnswerHeight(currentGroup.answers[0]) > freeHeight) {
          currentPageIndex += 1;
          pages.push([]);
          freeHeight = this.pageContentHeight;
          // eslint-disable-next-line no-continue
          continue;
        }

        // Минимум одна строка списка уже поместится на страницу
        freeHeight -= (curGroupTitleHeight + getAnswerHeight(currentGroup.answers[0]) + ANSWERS_LIST_MB);
        const rowsToAdd = [currentGroup.answers.shift()!];

        // Добираем оставшиеся строки из списка, которые влезут на страницу
        while (currentGroup.answers.length) {
          const rowHeight = getAnswerHeight(currentGroup.answers[0]);
          if (rowHeight > freeHeight) {
            break;
          }
          rowsToAdd.push(currentGroup.answers.shift()!);
          freeHeight -= rowHeight;
        }

        // Добавляем список на страницу с частью строк, которые влезают
        pages[currentPageIndex].push({
          ...currentGroup,
          answers: rowsToAdd,
        });

        currentGroup.question = undefined;

        // Если распределили все строки из списка, переходим к следующему
        if (!currentGroup.answers.length) {
          groups = groups.slice(1);
        }
      }

      return pages;
    },
    belowAnonymityThreshold() {
      return !this.chartData.state?.display && this.chartData.state?.anonymityThresholdReached;
    },
  },

  watch: {
    pages: {
      immediate: true,
      async handler() {
        if (this.pageContentHeight && this.belowAnonymityThreshold) {
          this.skipCurrentRenderer();
        }
        // Рендерер готов тогда, когда список страниц рассчитался с учетом высоты элементов
        if (this.dataIsLoaded
          && ((this.pageContentHeight && Object.values(this.elementsHeight).length)
          || !this.answerGroups.length)) {
          await this.$nextTick();
          if (this.chartData.questions.length) {
            this.collectAndEmitImages();
          } else {
            this.skipCurrentRenderer();
          }
        }
      },
    },
  },

  async mounted() {
    this.pageContentHeight = this.$el.querySelector('.page-content')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }

    await this.loadChartData();
    this.calculateElementsHeight();
  },

  methods: {
    async loadChartData() {
      try {
        const { data } = await tsxassApi.getV1AnalyticsFeedbackOpenQuestionsAnswers(
          this.surveyId,
          undefined,
          this.userId || undefined,
        );
        this.chartData = {
          questions: data?.questions || [],
          answers: data?.answers || [],
          state: data?.state || [],
        };
      } catch (err) {
        this.$emit('error');
      } finally {
        this.dataIsLoaded = true;
      }
    },
    getQuestionTitleElId(groupId: number | string) {
      return `group-title-${groupId}`;
    },
    getAnswerCardElId(answerId: string) {
      return `answer-${answerId}`;
    },
    calculateElementsHeight() {
      this.elementsHeight = getElementsOuterHeights(this.$el as HTMLElement);
    },
  },
});
