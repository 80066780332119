
import { defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import JohariZoneIcon from '@/components/analytics/pdf/widgets/johari/JohariZoneIcon.vue';
import JohariWindowWidget from '@/components/analytics/pdf/widgets/johari/JohariWindowWidget.vue';

export default defineComponent({
  name: 'PdfJohariRenderer',

  components: {
    PdfPage,
    JohariZoneIcon,
    JohariWindowWidget,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },

  computed: {
    zonesDescriptions() {
      return [
        {
          quadrantNumber: 3,
          name: this.$t('analytics.pdf.johari.developmentZone'),
          description: this.$t('analytics.pdf.johari.developmentZoneDescription'),
        },
        {
          quadrantNumber: 4,
          name: this.$t('analytics.pdf.johari.blindZone'),
          description: this.$t('analytics.pdf.johari.blindZoneDescription'),
        },
        {
          quadrantNumber: 1,
          name: this.$t('analytics.pdf.johari.hiddenPotentialZone'),
          description: this.$t('analytics.pdf.johari.hiddenPotentialZoneDescription'),
        },
        {
          quadrantNumber: 2,
          name: this.$t('analytics.pdf.johari.efficiencyZone'),
          description: this.$t('analytics.pdf.johari.efficiencyZoneDescription'),
        },
      ];
    },
  },

  methods: {
    onWidgetReady(e: { empty: boolean }) {
      if (e.empty) {
        this.skipCurrentRenderer();
      } else {
        this.collectAndEmitImages();
      }
    },
    onWidgetError() {
      this.$emit('error');
    },
  },
});
