
import { defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import {
  V1EntitiesAnalyticsFeedbackExtraQuestionsTempGroup,
  V1EntitiesAnalyticsFeedbackGeneralQuestionsTempItem,
} from '@/services/api/tsxass';
import PdfHeatMapWidget from '@/components/analytics/pdf/widgets/heatMap/PdfHeatMapWidget.vue';
import { HeatMapColumn, HeatMapRow as HeatMapRowType } from '@/components/analytics/charts/HeatMap/types';
import i18n from '@/plugins/vue-i18n';

const TABLE_HEADER_HEIGHT = 85;
const COLOR_CELL_COLUMNS_MAX_COUNT = 6;
const ANONYMITY_DESCRIPTION_HEIGHT = 20;

const getGroupColumnName = (groupId: number) => `group-${groupId}`;

export default defineComponent({
  name: 'PdfHeatMapRenderer',

  components: {
    PdfPage,
    PdfHeatMapWidget,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      // Высота зоны контента страницы
      pageContentHeight: 0,
      chartData: {
        groups: [] as V1EntitiesAnalyticsFeedbackExtraQuestionsTempGroup[],
        items: [] as V1EntitiesAnalyticsFeedbackGeneralQuestionsTempItem[],
        settings: {
          minValue: 1,
          maxValue: 5,
          displayBenches: false,
        },
      },
      dataIsLoaded: false,
      readyWidgetsCount: 0,
      tables: [] as HeatMapRowType[][],
      anonymityOfTables: [] as Boolean[],
      rawPageReady: false,
    };
  },

  computed: {
    fixedColumns(): HeatMapColumn[] {
      // определяет колонки таблицы тепловой карты
      console.log('override the computed value');
      return [];
    },
    allGroupColumns(): HeatMapColumn[] {
      return this.chartData.groups.map((group) => ({
        key: getGroupColumnName(group.id),
        title: group.name,
        isFixed: false,
        align: 'center',
      }));
    },
    allTableRows(): HeatMapRowType[] {
      const rows = this.chartData.items
        .map((item) => ({
          ...item,
          ...Object.fromEntries(
            item.values.map(({ groupId, value, options }) => [
              getGroupColumnName(groupId),
              this.getGroupColumnValue(value, options),
            ]),
          ),
        }))
        .sort((a, b) => a.sortIndex - b.sortIndex);

      const rootItems = rows
        .filter((row) => !row.parentId)
        .map((row) => [
          {
            ...row,
            isParent: true,
          },
        ]);
      const rootItemsMap = Object.fromEntries(rootItems.map((item) => [item[0].id, item]));
      rows
        .filter((row) => row.parentId)
        .forEach((row) => {
          rootItemsMap[row.parentId].push(row);
        });
      return rootItems.flat();
    },
    // Столбцы, распределенные по страницам (не включают в себя фиксированные столбцы)
    columnsPages(): HeatMapColumn[][] {
      const cols = this.chartData.groups.map((group) => ({
        key: getGroupColumnName(group.id),
        title: group.name,
        isFixed: false,
        align: 'center',
      }));

      const res = [];
      while (cols.length > 0) {
        res.push(cols.splice(0, COLOR_CELL_COLUMNS_MAX_COUNT));
      }
      return res.length ? res : [[]];
    },
    // Список фиксированных столбцов с заменённым значением ширины первого столбца (сделаем пошире, если есть место)
    fixedColumnsUpdated() {
      const pageWidth = (this.$el.querySelector('.page-content')?.getBoundingClientRect().width || 0) - 18;
      const groupColsWidth = (this.columnsPages[0]?.length || 0) * 42;
      const fixedColsWidth = this.fixedColumns.reduce((acc, col) => acc + (col.width || 0), 0);
      const fixedColsPadding = this.fixedColumns.length * 6;
      const firstColWidth = this.fixedColumns[0].width || 0;
      const firstColWidthUpdated = pageWidth - groupColsWidth - fixedColsWidth - fixedColsPadding + firstColWidth;
      return this.fixedColumns.map((col, idx) => ({
        ...col,
        width: idx ? col.width : firstColWidthUpdated,
      }));
    },
    pageTitle() {
      return '';
    },
  },

  watch: {
    chartData(val) {
      // Пропускаем рендер, если данных нет
      if (val.groups.length === 0 && val.items.length === 0) {
        this.skipCurrentRenderer();
      }
    },
  },

  async mounted() {
    this.pageContentHeight = this.$el.querySelector('.page-content')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }
    this.loadChartData();
  },

  methods: {
    async loadChartData() {
      // загружает данные тепловой карты, выставляет значения для chartData и dataIsLoaded
      console.log('override the method');
    },
    async onWidgetReady() {
      if (!this.pageContentHeight || !this.dataIsLoaded) {
        return;
      }

      this.readyWidgetsCount += 1;
      if (this.tables.length * this.columnsPages.length === this.readyWidgetsCount) {
        this.collectAndEmitImages();
      }
    },
    getGroupColumnValue(value, options) {
      if (options && !options.display) {
        return i18n.t('analytics.baseCompetencies.heatMap.anonymityThresholdReached');
      }
      if (typeof value === 'number') {
        return this.chartData.settings.minValue === -100 ? Number(value.toFixed(0)) : Number(value.toFixed(1));
      }
      return null;
    },
    onRawPageReady() {
      // Страница - пустышка отрендерилась, теперь считаем высоту каждой строки
      // и если очередная строка не помещается на текущую страницу, создаём новую страницу
      const rowElements = this.$el.querySelectorAll('.heat-map-rows-wrapper');
      const tables = [] as HeatMapRowType[][];
      const anonymityString = this.$t('analytics.baseCompetencies.heatMap.anonymityThresholdReached');
      let currentArray: HeatMapRowType[];
      let freeHeight = 0;
      rowElements.forEach((el, idx) => {
        const elHeight = el.getBoundingClientRect().height || 0;
        if (elHeight > freeHeight) {
          currentArray = [];
          tables.push(currentArray);
          freeHeight = this.pageContentHeight - TABLE_HEADER_HEIGHT - ANONYMITY_DESCRIPTION_HEIGHT;
        }
        currentArray.push(this.allTableRows[idx]);
        freeHeight -= elHeight;
      });
      this.tables = tables;
      this.anonymityOfTables = this.tables.map((t) => {
        const hasAnonymity = this.allGroupColumns.some((c) => t.some((r) => r[c.key] === anonymityString));
        return hasAnonymity;
      });
      this.rawPageReady = true;
    },
  },
});
