// Возвращает высоту элемента с учетом его отступов
export const getElementOuterHeight = (element: HTMLElement): number => {
  const height = element.offsetHeight;
  const style = window.getComputedStyle(element);

  return ['margin-top', 'margin-bottom']
    .map((key) => parseInt(style.getPropertyValue(key), 10))
    .reduce((total, side) => total + side, height);
};

// Возвращает объект со значениями высот элементов, отобранных по переданному атрибуту
export const getElementsOuterHeights = (parent: HTMLElement, dataAttrName = 'data-element-id') => {
  const res = {} as Record<string, number>;
  const elements = parent.querySelectorAll(`[${dataAttrName}]`);
  elements.forEach((element) => {
    const id = element.getAttribute(dataAttrName);
    if (id) {
      res[id] = getElementOuterHeight(element as HTMLElement);
    }
  });

  return res;
};
