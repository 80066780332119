
import { defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';

export default defineComponent({
  name: 'PdfTitlePageRenderer',

  components: {
    PdfPage,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyName: {
      type: String,
      default: '',
    },
    surveyeeName: {
      type: String,
      default: '',
    },
  },

  computed: {
    exportDate(): string {
      return this.$dayjs().format('DD.MM.YYYY');
    },
  },

  async mounted() {
    // ждём, когда страницы отрендерятся
    await this.$nextTick();
    // превращаем их в картинки
    this.collectAndEmitImages();
  },
});
