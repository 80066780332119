
import Vue, { PropType } from 'vue';
import {
  ChartConfiguration, ChartDataset, Chart, registerables, LegendItem,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CustomChartLegendPlugin from '@/utils/charts/plugins/CustomChartLegendPlugin';
import labelToArray from '@/utils/charts/labelToArray';
import type { Context } from 'chartjs-plugin-datalabels/types/context';
import ChartLegend from './ChartLegend.vue';
import { BarChartDataSet, BarChartDataSetData } from './types';

Chart.register(...registerables);

const TICK_FONT_SIZE = 10;

export default Vue.extend({
  name: 'HorizontalBarChart',

  components: {
    ChartLegend,
  },

  props: {
    barLabels: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    dataSets: {
      type: Array as PropType<BarChartDataSet[]>,
      default: () => [],
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    hideLegend: {
      type: Boolean,
      default: false,
    },
    barLabelWidthPx: {
      type: Number,
      default: 150,
    },
  },

  data() {
    return {
      chart: null as Chart<'bar' | 'line', BarChartDataSetData[]> | null,
      legendItems: [] as LegendItem[],
    };
  },

  computed: {
    preparedDataSets(): ChartDataset<'bar' | 'line', BarChartDataSetData[]>[] {
      return this.dataSets.map((dataSet) => ({
        ...dataSet,
        type: 'bar',
        parsing: {
          yAxisKey: 'y',
          xAxisKey: 'x',
        },
        maxBarThickness: 26,
        minBarLength: 2,
      }));
    },
    chartSettings(): ChartConfiguration<'bar' | 'line', BarChartDataSetData[]> {
      const isLabelInsideBar = (context: Context) => {
        const value = context.dataset.data?.[context.dataIndex]?.x;
        const label = context.dataset.data?.[context.dataIndex]?.label;
        const { min, max } = context.chart.scales.x.getMinMax(false);
        const xTickWidthPx = context.chart.chartArea.width / (max - min);

        return label.length * TICK_FONT_SIZE * 0.6 > (max - value) * xTickWidthPx;
      };

      const { barLabelWidthPx } = this;

      return {
        type: 'bar',
        data: {
          labels: this.barLabels,
          datasets: this.preparedDataSets,
        },
        options: {
          devicePixelRatio: 4, // увеличивает качество печати
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              position: 'left',
              ticks: {
                color: '#47585B',
                padding: 14,
                font: {
                  size: TICK_FONT_SIZE,
                },
                autoSkip: false,
                crossAlign: 'far',
                callback(val) {
                  const label = this.getLabelForValue(val as number);
                  const symbolsInLineCount = Math.floor(barLabelWidthPx / TICK_FONT_SIZE);
                  return labelToArray(String(label), symbolsInLineCount, 2);
                },
              },
              grid: {
                color: '#E7EBED',
                tickLength: 0,
                lineWidth(val) {
                  return val.index === 0 ? 1 : 0;
                },
                borderDash: [2, 2],
              },
            },
            x: {
              suggestedMin: this.minValue,
              suggestedMax: this.maxValue,
              ticks: {
                callback(val) {
                  return !(Number(val) % 20) ? `${val}%` : '';
                },
                font: {
                  size: TICK_FONT_SIZE,
                },
                padding: 12,
                stepSize: 10,
                align: 'inner',
                color: '#47585B',
              },
              grid: {
                z: -1,
                tickLength: 0,
                color: '#E7EBED',
              },
            },
          },
          animation: false,
          plugins: {
            datalabels: {
              align(context: Context) {
                return isLabelInsideBar(context) ? 'start' : 'end';
              },
              color(context: Context) {
                return isLabelInsideBar(context) ? '#FFFFFF' : '#63676B';
              },
              anchor: 'end',
              offset: 4,
              font: {
                size: TICK_FONT_SIZE,
                family: 'Roboto',
              },
              formatter: (value) => value.label,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        },
        plugins: [
          ChartDataLabels,
          CustomChartLegendPlugin(this, 'legendItems'),
        ],
      };
    },
  },

  watch: {
    chartSettings: {
      handler(val) {
        if (!this.chart) {
          return;
        }

        const { data, options } = val;
        const { datasets, labels } = data;
        this.chart.data.datasets = datasets;
        this.chart.data.labels = labels;
        this.chart.options = options;

        this.chart.update();
      },
    },
  },

  mounted() {
    this.chart = new Chart(this.$refs.canvasEl as HTMLCanvasElement, this.chartSettings);
    this.$emit('ready');
  },

  beforeDestroy() {
    this.chart?.destroy();
  },
});
