
import { defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';

export default defineComponent({
  name: 'PdfMetodologyDescriptionRenderer',

  components: {
    PdfPage,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loadedImagesCount: 0,
    };
  },

  computed: {
    listItems() {
      return [
        {
          imageName: 'ToysInBox.png',
          title: this.$t('analytics.pdf.metodologyDescription.assessmentCompleteness'),
          description: this.$t('analytics.pdf.metodologyDescription.assessmentCompletenessDescription'),
        },
        {
          imageName: 'Avatar.png',
          title: this.$t('analytics.pdf.metodologyDescription.evaluationGroups'),
          description: this.$t('analytics.pdf.metodologyDescription.evaluationGroupsDescription'),
        },
        {
          imageName: 'LockA.png',
          title: this.$t('analytics.pdf.metodologyDescription.anonymity'),
          description: this.$t('analytics.pdf.metodologyDescription.anonymityDescription'),
        },
      ];
    },
  },

  watch: {
    loadedImagesCount: {
      immediate: true,
      async handler(newValue) {
        if (newValue && this.$el.querySelectorAll('img').length === newValue) {
          await this.$nextTick();
          this.collectAndEmitImages();
        }
      },
    },
  },

  methods: {
    onImageLoad() {
      this.loadedImagesCount += 1;
    },
  },
});
