
import { defineComponent } from 'vue';
import { tsxassApi } from '@/services/api';
import { V1EntitiesAnalyticsFeedbackAverageRate } from '@/services/api/tsxass';
import { getScoreIconByValue, getNpsScaleScoreIconByValue } from '@/helpers/getScoreIcon';

export default defineComponent({
  name: 'AverageRatingWidget',

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      averageRatingData: null as null | V1EntitiesAnalyticsFeedbackAverageRate,
    };
  },

  computed: {
    anonimityReached(): boolean {
      const optionsAvg = this.averageRatingData?.optionsAvg;
      if (!optionsAvg) {
        return false;
      }
      return !optionsAvg.display && optionsAvg.anonymityThresholdReached;
    },
    avg(): string {
      if (this.anonimityReached) {
        return '—';
      }
      const min = this.averageRatingData?.settings.minValue;
      const avg = this.averageRatingData?.avg as number;
      return min === -100 ? avg.toFixed(0) : avg.toFixed(1);
    },
    bench(): string {
      const min = this.averageRatingData?.settings.minValue;
      const bench = this.averageRatingData?.bench as number;
      return min === -100 ? bench.toFixed(0) : bench.toFixed(1);
    },
    averageRatingLabelStyle(): Record<string, string> {
      if (this.anonimityReached) {
        return {
          backgroundColor: 'gray',
        };
      }
      const min = this.averageRatingData?.settings.minValue;
      const max = this.averageRatingData?.settings.maxValue;
      if (min === -100) {
        return {
          backgroundColor: getNpsScaleScoreIconByValue(this.averageRatingData?.avg as number).mutedColorCode,
        };
      }
      const backgroundColor = getScoreIconByValue(
        this.averageRatingData?.avg,
        min,
        max,
      ).mutedColorCode;

      return {
        backgroundColor,
      };
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    async loadData() {
      try {
        const { data } = await tsxassApi.getV1AnalyticsFeedbackAverageRate(
          this.surveyId,
          undefined,
          this.userId || undefined,
        );
        this.averageRatingData = data?.count > 0 ? data : null;
        await this.$nextTick();

        this.$emit('ready', { empty: this.averageRatingData === null });
      } catch (err) {
        this.$emit('error', err);
      }
    },
  },
});
