
import { defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import {
  V1EntitiesAnalyticsFeedbackDestructorsSurveySettings,
  V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis,
  V1EntitiesAnalyticsFeedbackDestructorsSurveyValue,
} from '@/services/api/tsxass';
import { defaultBarChartColor } from '@/constants/chartColors';
import { BarChartDataSetData } from '@/components/analytics/charts/types';
import { tsxassApi } from '@/services/api';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';
import HorizontalBarChart from '@/components/analytics/charts/HorizontalBarChart.vue';

interface PageData {
  labels: string[],
  values: BarChartDataSetData[],
}

const Y_TICK_HEIGHT = 32;
const CHART_PADDINGS_HEIGHT = 64;

export default defineComponent({
  name: 'PdfDestructorBarsRenderer',

  components: {
    PdfPage,
    HorizontalBarChart,
    NoFilteredDataStub,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    pageTitle: {
      type: String,
      default: '',
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      // Высота зоны контента страницы
      pageContentHeight: 0,
      defaultBarChartColor,
      chartData: {
        settings: {} as V1EntitiesAnalyticsFeedbackDestructorsSurveySettings,
        axis: [] as V1EntitiesAnalyticsFeedbackDestructorsSurveyAxis[],
        values: [] as V1EntitiesAnalyticsFeedbackDestructorsSurveyValue[],
        state: {
          belowAnonymityThreshold: false,
        },
      },
      dataIsLoaded: false,
      readyWidgetsCount: 0,
    };
  },

  computed: {
    yLabels(): string[] {
      return this.chartData ? this.chartData.axis.map((tick) => tick.name) : [];
    },
    dataSet(): BarChartDataSetData[] {
      const yValuesMap = Object.fromEntries(this.chartData.axis.map((item, index) => [item.id, index]));

      const data = (this.chartData?.values || []).map((tickValue) => ({
        y: yValuesMap[tickValue.axisId],
        x: tickValue.percTotal * 100,
        label: tickValue.hint,
      })).sort((a, b) => a.y - b.y);

      return data;
    },
    pages(): PageData[] {
      const maxRowsInChartCount = (this.pageContentHeight - CHART_PADDINGS_HEIGHT) / Y_TICK_HEIGHT;
      const res = [];
      const values = [...this.dataSet];
      const labels = [...this.yLabels];

      while (values.length) {
        res.push({
          values: values.splice(0, maxRowsInChartCount),
          labels: labels.splice(0, maxRowsInChartCount),
        });
      }

      return res.length ? res : [{ values: [], labels: [] }];
    },
    belowAnonymityThreshold() {
      return !this.chartData.state?.display && this.chartData.state?.belowAnonymityThreshold;
    },
  },

  mounted() {
    this.pageContentHeight = this.$el.querySelector('.page-content')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }

    this.loadChartData();
  },

  methods: {
    chartHeightByBarsCount(barsCount: number): number {
      return barsCount * Y_TICK_HEIGHT + CHART_PADDINGS_HEIGHT;
    },
    async loadChartData() {
      try {
        const { data } = await tsxassApi.getV1AnalyticsFeedbackDestructorsSurvey(
          this.surveyId,
          undefined,
          this.userId || undefined,
        );

        this.chartData = {
          settings: data.settings || {},
          axis: data.axis || [],
          values: data.values || [],
          state: data.state || {},
        };
      } catch (err) {
        this.$emit('error');
      } finally {
        this.dataIsLoaded = true;
      }
    },
    async onWidgetReady() {
      if (!this.pageContentHeight || !this.dataIsLoaded) {
        return;
      }
      this.readyWidgetsCount += 1;
      if (this.pages.length === this.readyWidgetsCount) {
        if (this.pages[0].values.length > 0) {
          this.collectAndEmitImages();
        } else {
          this.skipCurrentRenderer();
        }
      }
    },
    onWidgetError() {
      this.$emit('error');
    },
  },
});
