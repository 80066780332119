
import { defineComponent } from 'vue';
import PdfJohariRenderer from '@/components/analytics/pdf/renderers/PdfJohariRenderer.vue';
import PdfJohariZonesRenderer from '@/components/analytics/pdf/renderers/PdfJohariZonesRenderer.vue';

/**
* Компонент-обертка над рендерерами страницы с окном Джохари (PdfJohariRenderer)
* и страниц с расшифровкой графика (PdfJohariZonesRenderer).
* Возвращает пустой массив отрендеренных страниц, в случае если один из рендереров оказался пустой.
*/
export default defineComponent({
  name: 'PdfJohariSectionRenderer',

  components: {
    PdfJohariRenderer,
    PdfJohariZonesRenderer,
  },

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    domToImage: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      // Сохраняем тут картинки локально
      images: [] as string[],
      successRenders: 0,
      skip: false,
    };
  },

  computed: {},

  methods: {
    async onRendererSuccess(images: string[]) {
      if (this.skip) return;
      this.successRenders += 1;

      // Если хотя бы один рендерер пустой, пропускаем все
      if (!images.length) {
        this.$emit('success', []);
        this.skip = true;
        return;
      }

      this.images.push(...images);

      if (this.successRenders === 2) {
        this.$emit('success', this.images);
      }
    },
    onRendererError() {
      this.$emit('error');
    },
  },
});
