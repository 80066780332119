
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'JohariZoneIcon',

  props: {
    number: {
      type: Number,
      default: 0,
    },
  },
});
