
import { defineComponent, PropType } from 'vue';
import type { TranslateResult } from 'vue-i18n';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import { V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion } from '@/services/api/tsxass';
import NoFilteredDataStub from '@/components/analytics/widgets/NoFilteredDataStub.vue';
import JohariZoneIcon from '@/components/analytics/pdf/widgets/johari/JohariZoneIcon.vue';
import GradeValueSmile from '@/components/analytics/charts/GradeValueSmile.vue';
import { getElementsOuterHeights } from '@/helpers/getElementOuterHeight';

interface QuestionsGroup {
  title?: string;
  id: string;
  rows: V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[];
}

const TABLE_TITLE_HEIGHT = 24;
const TABLE_HEADER_HEIGHT = 22;
const TABLE_ROW_HEIGHT = 42;
const TABLES_GAP = 16;

export default defineComponent({
  name: 'PdfJohariZoneRenderer',

  components: {
    PdfPage,
    JohariZoneIcon,
    GradeValueSmile,
    NoFilteredDataStub,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    zoneTitle: {
      type: String as PropType<TranslateResult | string>,
      default: '',
    },
    quadrantNumber: {
      type: Number,
      required: true,
    },
    description: {
      type: String as PropType<TranslateResult | string>,
      default: '',
    },
    questions: {
      type: Array as PropType<V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[]>,
      default: () => [],
    },
    minRating: {
      type: Number,
      default: 0,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
    belowAnonymityThreshold: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // Высота зоны страницы, в которой будут выводиться таблицы
      pageContentHeight: 0,
      // Значения высот элементов на странице
      elementsHeight: {} as Record<string, number>,
    };
  },

  computed: {
    cssVars(): Record<string, any> {
      return {
        '--table-title-height': `${TABLE_TITLE_HEIGHT}px`,
        '--table-header-height': `${TABLE_HEADER_HEIGHT}px`,
        '--table-row-height': `${TABLE_ROW_HEIGHT}px`,
        '--tables-gap': `${TABLES_GAP}px`,
      };
    },
    // Вопросы, сгруппированные в таблицы
    tables(): QuestionsGroup[] {
      const questionGroupsMap: Record<string, V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[]> = {};
      const questionsWithoutGroup: V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion[] = [];
      this.questions.forEach((item, qIdx) => {
        const key = item.groupQuestionName || '';
        const uniqueItem = {
          ...item,
          coordinateId: String(qIdx),
        };
        if (!key) {
          questionsWithoutGroup.push(uniqueItem);
        } else {
          if (!questionGroupsMap[key]) {
            questionGroupsMap[key] = [];
          }
          questionGroupsMap[key].push(uniqueItem);
        }
      });

      const groups = Object.entries(questionGroupsMap).map(([title, rows], gIdx) => ({
        title,
        id: String(gIdx),
        rows,
      }));
      return [
        {
          title: '',
          rows: questionsWithoutGroup,
          id: '0',
        },
        ...groups,
      ].filter((table) => !!table.rows.length);
    },
    // Таблицы, распределенные по страницам
    pages(): QuestionsGroup[][] {
      // eslint-disable-next-line arrow-body-style
      const getGroupTitleHeight = (group: QuestionsGroup) => {
        return this.elementsHeight?.[this.getGroupTitleElId(group.id)] || 0;
      };
      // eslint-disable-next-line arrow-body-style
      const getQuestionHeight = (question: V1EntitiesAnalyticsFeedbackJohariQuestionsQuestion) => {
        return this.elementsHeight?.[this.getQuestionElId(question.coordinateId)] || 0;
      };

      // Итоговый массив
      const pages: QuestionsGroup[][] = [];
      let currentPage: QuestionsGroup[] = [];
      let currentGroup: QuestionsGroup;
      // Оставшаяся незаполненная высота на текущей странице
      let freeHeight = 0;

      this.tables.forEach((table) => {
        currentGroup = {
          ...table,
          rows: [],
        };
        const tableTitleHeight = getGroupTitleHeight(table) + TABLE_HEADER_HEIGHT;
        const totalHeaderHeight = tableTitleHeight + (currentPage.length > 0 ? TABLES_GAP : 0);
        if (totalHeaderHeight >= freeHeight) {
          currentPage = [];
          pages.push(currentPage);
          freeHeight = this.pageContentHeight;
        }
        currentPage.push(currentGroup);
        freeHeight -= totalHeaderHeight;
        table.rows.forEach((row) => {
          const rowHeight = getQuestionHeight(row);
          if (rowHeight >= freeHeight) {
            if (currentGroup.rows.length === 0) {
              currentPage.pop();
            }
            currentGroup = {
              ...table,
              rows: [],
            };
            currentPage = [currentGroup];
            pages.push(currentPage);
            freeHeight = this.pageContentHeight - tableTitleHeight;
          }
          currentGroup.rows.push(row);
          freeHeight -= rowHeight;
        });
      });
      return pages;
    },
  },

  watch: {
    pages: {
      async handler(newValue) {
        // Рендерер готов тогда, когда список страниц рассчитался с учетом высоты элементов
        if ((this.pageContentHeight && Object.values(this.elementsHeight).length) || !this.questions.length) {
          await this.$nextTick();
          this.$emit('ready', { pagesCount: newValue.length });
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    this.pageContentHeight = this.$el.querySelector('.tables')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }
    this.calculateElementsHeight();
  },

  methods: {
    getGroupTitleElId(groupId: string) {
      return `group-title-${groupId}`;
    },
    getQuestionElId(questionId: string) {
      return `question-${questionId}`;
    },
    calculateElementsHeight() {
      this.elementsHeight = getElementsOuterHeights(this.$el as HTMLElement);
    },
  },
});
