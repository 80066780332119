
import { defineComponent } from 'vue';
import SimpleJohariWindow from '@/components/analytics/charts/GradeVisualization/SimpleJohariWindow.vue';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesAnalyticsFeedbackJohariItem,
} from '@/services/api/tsxass';

export default defineComponent({
  name: 'JohariWindowWidget',

  components: {
    SimpleJohariWindow,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      chartData: {
        values: [] as V1EntitiesAnalyticsFeedbackJohariItem[],
        settings: {
          minValue: 1,
          maxValue: 5,
        },
      },
      dataIsLoaded: false,
    };
  },

  mounted() {
    this.loadChartData();
  },

  methods: {
    async loadChartData() {
      try {
        const { data: { items, settings } } = await tsxassApi.getV1AnalyticsFeedbackJohari(
          this.surveyId,
          undefined,
          this.userId,
        );
        this.chartData = {
          values: items,
          settings,
        };

        this.dataIsLoaded = true;
      } catch (err) {
        this.$emit('error', err);
      }
    },
  },
});
