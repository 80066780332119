
import { defineComponent, PropType } from 'vue';
import { V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion } from '@/services/api/tsxass';
import AnswersApportionmentWidget from '@/components/analytics/widgets/AnswersApportionmentWidget.vue';

export default defineComponent({
  name: 'PdfAnswersApportionmentWidget',

  components: {
    AnswersApportionmentWidget,
  },

  props: {
    question: {
      type: Object as PropType<V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion>,
      required: true,
    },
    minRating: {
      type: Number,
      default: 1,
    },
    maxRating: {
      type: Number,
      default: 5,
    },
  },
  async mounted() {
    await this.$nextTick();
    this.$emit('ready');
  },
});
