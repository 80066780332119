
import { defineComponent } from 'vue';
import { V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import PdfAnswersApportionmentWidget from '@/components/analytics/pdf/widgets/distribution/PdfAnswersApportionmentWidget.vue';

const WIDGET_HEADER_HEIGHT = 90;
const ROW_HEIGHT = 30;

export default defineComponent({
  name: 'PdfAnswersDistributionRenderer',

  components: {
    PdfPage,
    PdfAnswersApportionmentWidget,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      // Высота зоны контента страницы
      pageContentHeight: 0,
      chartData: {
        questions: [] as V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion[],
        settings: {
          minValue: 1,
          maxValue: 5,
        },
      },
      dataIsLoaded: false,
      readyWidgetsCount: 0,
    };
  },

  computed: {
    // Список виджетов, распределенных по страницам
    widgets() {
      // Итоговый массив, разбитый на страницы
      const widgets = [] as V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion[][];
      // страница итогового массива
      let currentPage: V1EntitiesAnalyticsFeedbackExtraQuestionsScalesQuestion[];
      let freeHeight = 0;
      // перебираем все вопросы
      this.chartData.questions.forEach((question) => {
        // сколько места требуется очередному виджету
        const heightToAdd = WIDGET_HEADER_HEIGHT + question.answers.length * ROW_HEIGHT;
        if (!currentPage || (freeHeight < heightToAdd)) {
          // место закончилось, начинаем новую страницу; вся высота страницы снова доступна
          freeHeight = this.pageContentHeight;
          currentPage = [];
          widgets.push(currentPage);
        }
        currentPage.push(question);
        freeHeight -= heightToAdd;
      });
      return widgets.length ? widgets : [[]];
    },
  },

  watch: {
    chartData(val) {
      // Пропускаем рендер, если данных нет
      if (val.questions.length === 0) {
        this.skipCurrentRenderer();
      }
    },
  },

  async mounted() {
    this.pageContentHeight = this.$el.querySelector('.page-content')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }

    this.loadChartData();
  },

  methods: {
    async loadChartData() {
      try {
        const { data } = await tsxassApi.getV1AnalyticsFeedbackExtraQuestionsScales(
          this.surveyId,
          undefined,
          this.userId || undefined,
        );
        this.chartData = {
          questions: data.questions || [],
          settings: data.settings || { minValue: 1, maxValue: 5 },
        };
      } catch (err) {
        this.$emit('error');
      } finally {
        this.dataIsLoaded = true;
      }
    },
    async onWidgetReady() {
      if (!this.pageContentHeight || !this.dataIsLoaded) {
        return;
      }

      this.readyWidgetsCount += 1;
      if (this.widgets.length === this.readyWidgetsCount) {
        this.collectAndEmitImages();
      }
    },
  },
});
